import React from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <Layout>
  <section className="page bg-blue-3 text-white">
    <div className="container my-5 py-5">
      <div className="row my-5 py-5">
        <div className="col-md-6 mx-auto">
          <span className="display-1">404</span>
          <h1>PAGE NOT FOUND</h1>
          <p>Sorry we can't find the page you're looking for.</p>
          <Link class="btn btn-outline-secondary" to="/">Go to home page</Link>
        </div>
      </div>
    </div>
  </section>
  </Layout>
);

export default NotFoundPage
